import React from "react"

import WorkPageLayout from "./Components/WorkPageLayout"
import { Box, Flex } from "../../Components/Base"
import { WORK_ID, WORK } from "../../constant"

import Content from "./Components/Content"

const workId = WORK_ID.SHOWFLAT
const workData = WORK.find(w => w.key === workId)
const ROOT_IMAGE_PATH = `/assets/work/${workId}`

const HeaderSection = () => {
  return (
    <Box>
      <Content.Header>{workData.name}</Content.Header>
      <Content.SubHeader>role: {workData.role}</Content.SubHeader>
      <Flex flexDirection={["column", "row"]} justifyContent={"space-between"}>
        <Content.HeaderParagraph width={"70%"}>
          The Sales gallery for the luxury Low-rise condominium called 168
          residence, which located on Sukhumvit36 road, one of the most crowded
          area in Bangkok. The team was requested to design the stunning
          architecture which represent the characteristic of the main building
          and attract the potential buyers who pass by.
        </Content.HeaderParagraph>
        <Content.TextField
          textAlign={"right"}
          size={"small"}
          fields={[
            {
              field: "location",
              value: "Bangkok, Thailand",
            },
            { field: "program", value: "Sale Gallery" },
            { field: "client", value: "Advanced Living" },
            { field: "status", value: "completion 2017" },
            { field: "area", value: "400 sq.m." },
          ]}
        />
      </Flex>
    </Box>
  )
}

const ContentSection = () => {
  return (
    <Content as={"article"}>
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/thumbnail.jpg`}
          height={"500px"}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>

      <Content.Section title={"Idea"}>
        <Content.Paragraph width={"100%"}>
          The small triangle-like shape of the site forced us to compress all
          the main functions into the wide area of the triangle and leave the
          narrow area of the triangle as the landscape for the outdoor events
          and lead the eyes of the passerby to the main functions.
        </Content.Paragraph>
        <Content.Paragraph>
          The aluminium panel, which is also the main material for the
          condominium, is used to wrap the building to create the sculptural
          roof form and attract the passerby on the road. This perforated facade
          offers the privacy for certain area such as meeting room and also
          filter the natural light to create interesting shadow effect.
        </Content.Paragraph>
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-01.svg`}
          index={1}
          description={
            "The site has a small triangle-like shape located on one of the most crowded road in Bangkok"
          }
        />
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-02.svg`}
          index={2}
          description={
            "Compress all the main functions into the wide area of the triangle and leave the narrow area of the triangle as the landscape for the outdoor events"
          }
        />
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-03.svg`}
          index={3}
          description={
            "Wrap the second floor with perforated aluminium facade to create stunning eyepoint and at the same time create privacy for certain area while still allow natural light to come in"
          }
        />
        <Content.DetailCard
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-04.svg`}
          index={4}
          description={
            "Full-height windows on the first floor allow the passerby to look directly to the show units and at the same time create the floating effect of the aluminium facade"
          }
        />
      </Content.Section>

      <Content.Section
        mb={5}
        fullWidth={false}
        left={
          <>
            <Content.Title>Stunning eyepoint</Content.Title>
            <Content.Paragraph pr={5}>
              During the rush hours, this road is famed as one of the most
              traffic jam in Bangkok. The team takes this as the design
              advantage by wrapping the the 2nd floor and the roof with the
              aluminum facade along the road to attract the potential buyers who
              pass by.
            </Content.Paragraph>
            <Content.Paragraph pr={5}>
              The full-height windows on the 1st floor are used to create the
              floating effect for the facade and also allow the potential buyers
              to look directly into the show units.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`${ROOT_IMAGE_PATH}/exterior-03.jpg`}
            height={"520px"}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />

      <Content.Section>
        <Content.Image
          src={`${ROOT_IMAGE_PATH}/exterior-01.jpg`}
          caption={"view from the road"}
        />
        <Content.Image
          src={`${ROOT_IMAGE_PATH}/facade-02.jpg`}
          caption={"perforated aluminium facade"}
        />
      </Content.Section>

      <Content.Section>
        <Content.Title>Natural light & Privacy</Content.Title>
        <Content.Paragraph>
          The perforated aluminum panel is used to give the privacy to the
          specific area such as a meeting room while still allow the natural
          light to come in. The pattern of the perforated panel filter the
          natural light and result in the pattern of light and shadow inside the
          building.
        </Content.Paragraph>

        <Content.Image
          src={`${ROOT_IMAGE_PATH}/interior-court-02.jpg`}
          caption={
            "the perforated aluminium panel offer privacy for the meeting room and filter the natural light creating the interesting effect of light and shadow inside"
          }
        />
        {/* <Content.Image
        src={`/assets/work/${workId}/interior-court-02.jpg`}
        width={"100%"}
        caption={"the effect of the aluminuium cladding"}
      /> */}
      </Content.Section>

      <Content.Section
        mb={5}
        fullWidth={false}
        left={
          <>
            <Content.Title>Internal view</Content.Title>
            <Content.Paragraph pr={5}>
              Two courts are designed to separate the functions and create
              internal view for the users while using or walking through the
              building.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`/assets/work/${workId}/interior-court-03.jpg`}
            height={"380px"}
            style={{
              objectFit: "cover",
              objectPosition: "right",
            }}
          />
        }
      />

      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/interior-court-05.jpg`}
          width={"100%"}
          caption={
            "view of an internal court from the hallway on the second floor"
          }
        />
      </Content.Section>

      <Content.Section title={"Gallery"}>
        <Content.Image
          src={`/assets/work/${workId}/entrance-01.jpg`}
          width={["100%", "50%"]}
          height={"500px"}
          caption={"entrance"}
          style={{
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        <Content.Image
          src={`/assets/work/${workId}/entrance-02.jpg`}
          width={["100%", "50%"]}
          height={"500px"}
          caption={"entrance"}
          style={{
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        <Content.Image
          src={`/assets/work/${workId}/lobby.jpg`}
          width={"100%"}
          caption={"lobby"}
        />{" "}
        <Content.Image
          src={`/assets/work/${workId}/stairhall-01.jpg`}
          width={"100%"}
          caption={"stair hall"}
        />
        <Content.Image
          src={`/assets/work/${workId}/interior-01.jpg`}
          width={"100%"}
          caption={"show unit interior"}
        />
        <Content.Image
          src={`/assets/work/${workId}/plan-01.jpg`}
          caption={"1st floor plan"}
        />
        <Content.Image
          src={`/assets/work/${workId}/plan-02.jpg`}
          caption={"2nd floor plan"}
        />
        <Content.Image
          src={`/assets/work/${workId}/elevation-01.jpg`}
          width={"100%"}
          caption={"elevation"}
        />
        <Content.Image
          src={`/assets/work/${workId}/exterior-02.jpg`}
          width={"100%"}
          caption={"view from the road at night"}
        />
      </Content.Section>

      <Content.Section>
        <Content.TextField
          fields={[
            {
              field: "Architect (Archive Architect X AAd)",
              value: [
                "Ayutt Mahasom (AAd)",
                "Kanop Mangklapruk (Archive)",
                "Tadpol Rachatasumrit (Archive)",
              ],
            },
          ]}
        />
      </Content.Section>
    </Content>
  )
}
const Work = () => {
  return (
    <WorkPageLayout
      header={<HeaderSection />}
      fullWidthHeader={true}
      content={<ContentSection />}
      currentWork={workId}
    />
  )
}

export default Work
